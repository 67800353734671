<template>
  <div class="mb-3 col-12">
    <div class="row">
      <div class="col-md-6 col-12 form-group input-group">
        <input
          type="text"
          class="form-control form-control-sm"
          placeholder="Buscar.."
          v-model="params.search"
          @keyup="search_query()"
        />
        <span class="input-group-append">
          <button type="button" class="btn btn-sm btn-primary">
            <i class="fas fa-search"></i>
          </button>
        </span>
      </div>
    </div>
    <ul class="list-group list-group-flush mb-3" v-if="searching">
      <li class="list-group-item">
        <div class="skeleton">
          <div class="skeleton-author"></div>
          <div class="skeleton-content"></div>
          <div class="skeleton-content"></div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="skeleton">
          <div class="skeleton-author"></div>
          <div class="skeleton-content"></div>
          <div class="skeleton-content"></div>
        </div>
      </li>
    </ul>
    <ul class="list-group list-group-flush mb-3" v-else-if="rows.length == 0">
      <li class="list-group-item">
        <h5 class="text-center">No se encontraron resultados</h5>
      </li>
    </ul>
    <ul class="list-group list-group-flush mb-3" v-else>
      <li
        class="list-group-item hoverable"
        v-for="(row, i) in rows"
        :key="i"
        @click="rowClick(row.data)"
      >
        <div class="widget-content p-0">
          <div class="widget-content-wrapper row">
            <div class="widget-content-left col-12">
              <h5 class="widget-heading">
                <span v-for="titulo in row.titulos" :key="titulo.name" :class="titulo.class">
                  {{titulo.prepend}}
                    <span v-if="toCuil && toCuil.includes(titulo.name)">{{titulo.value | cuil}}</span>
                    <span v-else-if="toPesos && toPesos.includes(titulo.name)">{{titulo.value | numFormat('0,0.00')}}</span>
                    <span v-else-if="toDate && toDate.includes(titulo.name)">{{titulo.value | moment("DD/MM/YYYY")}}</span>
                    <span v-else>{{titulo.value}}</span>
                  {{titulo.append}}
                </span>
              </h5>
              <div class="widget-subheading opacity-10 row" v-if="row.campos">
                <span class="col-sm-auto col-12" v-for="(item, j) in row.campos" :key="j">
                  {{item.label}}:
                  <b :class="item.class">
                    {{item.prepend}}
                    <span v-if="toCuil && toCuil.includes(item.name)">{{item.value | cuil}}</span>
                    <span v-else-if="toPesos && toPesos.includes(item.name)">{{item.value | numFormat('0,0.00')}}</span>
                    <span v-else-if="toDate && toDate.includes(item.name)">{{item.value | moment("DD/MM/YYYY")}}</span>
                    <span v-else>{{item.value}}</span>
                    {{item.append}}
                  </b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="row" v-if="last_page > 1">
      <div class="col-12">
        <nav aria-label="...">
          <ul class="pagination justify-content-end pagination-sm">
            <li :class="['page-item', (current_page == 1) ? 'disabled': '']">
              <button class="page-link" @click="prev_page()">
                <i class="fas fa-angle-double-left"></i>
              </button>
            </li>
            <li
              :class="[(i == current_page) ? 'active': '', 'page-item']"
              v-for="i in paginate()"
              :key="i"
            >
              <button @click="goto_page(i)" class="page-link">{{i}}</button>
            </li>
            <li :class="['page-item', (current_page == last_page) ? 'disabled': '']">
              <button class="page-link" @click="next_page()">
                <i class="fas fa-angle-double-right"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "DataList",
  data() {
    return {
      rows: [],
      titulos: [],
      secundarios: [],
      params: {
        search: "",
        page: 1,
        paginate:true,
      },
      current_page: 1,
      last_page: null,
      timer: null,
      searching: false
    };
  },
  props: {
    url: null,
    fields: null,
    institucion: null,
    rowClick: { type: Function },
    modal: null,
    toPesos: null,
    toCuil: null,
    toDate: null
  },
  mounted() {
    if (!this.modal) {
      this.params.search = this.$route.query.search;
    }
    this.getData();
  },
  destroyed() {
    if(this.institucion){
      axios.defaults.headers.common['institucionId'] = JSON.parse(localStorage.getItem('institucion')).id;
    }
  },
  methods: {
    getData() {
      this.searching = true;
      axios
        .get(this.url, { params: this.params, cancelPreviousRequest: true})
        .then(res => {
          let response = res.data;
          this.current_page = response.current_page;
          this.last_page = response.last_page;
          let data = response.data;
          this.rows = [];
          data.map(item => {
            let fila = {
              titulos: this.getTitulos(item),
              campos: this.getSecundarios(item),
              data: item
            };
            this.rows.push(fila);
          });
          if (this.$route.query.search != this.params.search && !this.modal) {
            this.$router.replace({ query: { search: this.params.search } });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.searching = false;
        });
    },
    update() {
      this.getData();
    },
    next_page() {
      if (this.current_page < this.last_page) {
        this.params.page++;
        this.current_page++;
      }
      this.getData();
    },
    prev_page() {
      if (this.current_page > 1) {
        this.params.page--;
        this.current_page--;
      }
      this.getData();
    },
    goto_page(i) {
      this.current_page = i;
      this.params.page = this.current_page;
      this.getData();
    },
    search_query() {
      clearTimeout(this.timer); 
      this.timer = setTimeout(() => {
        this.params.page = 1;
        this.getData();
      }, 1000)
    },
    getFieldValue(fila, field) {
      let array = field.split(".");
      let i = 0;
      let value = "";
      for (let item of array) {
        if (i == 0) {
          value = fila[item];
        } else {
          value = value[item];
        }
        i++;
      }
      return value;
    },
    getSecundarios(fila) {
      let campos = [];
      this.fields.map(field => {
        let filaSecundaria = {
          name: null,
          class: null,
          value: null,
          append: null,
          prepend: null,
          label: null,
          filter: null
        };
        if (field.type == "field") {
          filaSecundaria.name = field.name;
          filaSecundaria.class = field.class;
          filaSecundaria.label = field.label;
          filaSecundaria.append = field.append;
          filaSecundaria.prepend = field.prepend;
          filaSecundaria.filter = {type: Function, value: field.filter};
          filaSecundaria.value = this.getFieldValue(fila, field.name);
          campos.push(filaSecundaria);
        }
      });
      return campos;
    },
    getTitulos(fila) {
      let titulos = [];
      this.fields.map(field => {
        let filaTitulo = {
          name: null,
          class: null,
          value: null,
          append: null,
          prepend: null,
          label: null,
          filter: null
        };
        if (field.type == "title") {
          filaTitulo.name = field.name;
          filaTitulo.class = field.class;
          filaTitulo.label = field.label;
          filaTitulo.append = field.append;
          filaTitulo.prepend = field.prepend;
          filaTitulo.filter = field.filter;
          filaTitulo.value = this.getFieldValue(fila, field.name);
          titulos.push(filaTitulo);
        }
      });
      return titulos;
    },
    paginate() {
      let pagination = [];
      if (this.current_page <= 2) {
        let to = this.last_page <= 5 ? this.last_page : 5;
        for (let i = 1; i <= to; i++) {
          pagination.push(i);
        }
      } else {
        let from = this.current_page - 2;
        let to =
          this.last_page <= this.current_page + 2
            ? this.last_page
            : this.current_page + 2;
        for (let i = from; i <= to; i++) {
          pagination.push(i);
        }
      }
      return pagination;
    }
  }
};
</script>
<style>
.skeleton-content,
.skeleton-author {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;
  background: #efeff0;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#e0e1e2),
    color-stop(0.2, #e0e1e2),
    color-stop(0.4, #efeff0),
    to(#efeff0)
  );
  background-image: -webkit-linear-gradient(
    left,
    #efeff0 0%,
    #e0e1e2 20%,
    #efeff0 40%,
    #efeff0 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 104px;
  position: relative;
}

div.skeleton-author {
  position: relative;
  margin: 15px 15px;
  height: 20px;
  width: 50%;
}

div.skeleton-content {
  position: relative;
  margin: 10px 15px;
  height: 10px;
  width: 100%;
}

@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }

  to {
    background-position: top right;
  }
}
</style>