<template>
  <CardForm :titulo="titleCard">
    <DataList ref="datatable" url="user" :fields="fields" class="mb-3" :toCuil="toCuil" :rowClick="opciones" />
  </CardForm>
</template>
<script>
import CardForm from '@/components/layouts/CardForm';
import DataList from '@/components/layouts/DataList';
import Swal from 'sweetalert2';
import axios from 'axios'
export default {
  name: "ListarUsuarios",
  components: {
    CardForm,
    DataList
  },
  props: {
      iconoTitulo: String,
      titleCard: String,
      toCreateAction: String,
      toCreateText: String,
    },
  data() {
    return {
      fields: [
        {
          name: "apellido",
          label: "",
          type: "title",
          class: "text-primary",
        },
        {
          name: "nombre",
          label: "",
          type: "title",
          class: "text-primary",
          prepend: ','
        },
        {
          name: "tipo",
          label: "TIPO",
          type: "field",
          class: "text-success",
        },
        {
          name: "cuil",
          label: "CUIL",
          type: "field",
          class: "text-secondary",
        },
        {
          name: "email",
          label: "Email",
          type: "field",
          class: "text-secondary",
        },
        {
          name: "whatsapp",
          label: "Whatsapp",
          type: "field",
          class: "text-secondary",
        },
      ],
      toCuil: ['cuil']
    };
  },
  methods: {
    opciones(usuario) {
       this.$router.push({name:"VerUsuario", params:{id:usuario.id}})
    },
  },
};
</script>
